import React from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/Header';
import Image from '../components/Image';
import Page from '../components/Page';
import './index.scss';

export default function Home() {

  const imageProps = {
    alt: "placeholder",
    className: "index-image",
  };

  return (
    <div className="site">
      <Helmet title="Kúsok prírody" />
      <Header navigationIndex={0} />
      <Page>
        <div className="segment-centered">
          <p className="index-lead">Každý <span className="highlighted">Kúsok prírody</span> je unikátna ručná práca, ktorá oživí tvoj domov</p>
        </div>
        <Image src="asu-placeholder-1.jpg" {...imageProps} />
        <Image src="asu-placeholder-2.jpg" {...imageProps} />
        <Image src="asu-placeholder-3.jpg" {...imageProps} />
        <div className="segment-centered">
          <div className="index-features">
            <h2 className="highlighted">Vlastnosti</h2>
            <ul>
              <li>Použité materiály sú pet-friendly. Kúsky prírody sú vhodným habitatom pre drobné tvory, napríklad krevety.</li>
              <li>Voliteľné vodopády a hmlopády</li>
              <li>Môžeš si vybrať hlasitosť vodopádu podľa vlastnej preferencie</li>
            </ul>
          </div>
        </div>
      </Page>
    </div>
  );
}
