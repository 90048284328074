import React from 'react';
import Navigation from './Navigation';
import "./Header.scss";

const Header = ({navigationIndex}) => {

  const navigationItems = [
    {
      href: "#",
      label: "Domov",
    },
    {
      href: "#",
      label: "Modely",
    },
    {
      href: "#",
      label: "Kontakt",
    },
  ];

  return (
    <div className="header-container">
      <Navigation items={navigationItems} selected={navigationIndex} />
      <a href=""><h1>Kúsok prírody</h1></a>
      <Navigation items={[{ href: '#', label: 'Instagram' }]} className="header-right" />
    </div>
  );
}

export default Header;
