import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import "./Navigation.scss";

const Navigation = ({ items, selected, className }) => {

  const [collapsedState, setCollapsed] = useState(true);

  const handleMenuClick = (event) => {
    setCollapsed(!collapsedState);
  };

  const handleMenuBlur = (event) => {
      setCollapsed(true);
  };

  const isCollapsible = items.length > 1;
  const classNames = cx(
    className,
    "navigation-container",
    isCollapsible && 'navigation-collapsible',
    isCollapsible && !collapsedState && 'navigation-expanded',
  );
  return (
    <>
      { isCollapsible && <button
        className={"navigation-hamburger-icon"}
        onClick={handleMenuClick}
        onBlur={handleMenuBlur}
      >☰</button>}
      <ul className={classNames}>
        {items.map((item, index) => (
          <li
            key={item.label}
            className={
              index === selected
                ? "navigation-item-selected"
                : "navigation-item-not-selected"
            }
          >
            <a href={item.href}>{item.label}</a>
          </li>
        ))}
      </ul>
    </>
  );
}

export default Navigation;
